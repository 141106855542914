html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
/* @import "@blueprintjs/icons/lib/css/blueprint-icons.css"; */
