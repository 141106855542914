.each-slide-effect>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
  width: 800px;
}

.each-slide-effect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

@media (max-width: 600px) {
  .button-text {
    display: none;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}